import React, {Component} from 'react';

import '../styles/pages/Home.css';

import Form from '../components/home/Form';
import Video from '../components/home/Video'
import AppStoreBadge from '../components/home/App-store-badge'
import GooglePlayBadge from '../components/home/Google-play-badge'

class Home extends Component {
  constructor(){
    super()
    this.state = {
     
    }
  }
  render(){
    return (
      <div className='Home'>
        <div className="App-header">
            <Video/>
        </div>
        <div className="App-bottom">
          <div className="App-text">
                <div className="title">
                  RadarExplorer
                </div>
                <div style={{fontSize: '20px'}}>
                  Di adiós a las multas, y conduce seguro.
                </div>
            </div>
            {/*
            <div className="App-subscribe">
              <Form/>
            </div>
            */}
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <AppStoreBadge/>
            </div>
            <br/>
            <div className="AppStore">
              <div style={{fontSize: '12px'}}>
                <a href="https://radarexplorer.app/blog/" style={{textDecoration: 'none', color: '#ffffff'}} target="_blank">Blog</a>
              </div>
            </div>
            <br/>
            <br/>
          </div>
        {/*<div className="App-footer">
          @wearenada
    </div>*/}
      </div>
    );
  }
}

export default Home;
