import React, {Component} from 'react'
import oneVideo from '../../images/onesland.mov';
import oneVideoMp4 from '../../images/onesland.mp4';
import oneVideoMask from '../../images/video_mask.svg';

import AppIcon from '../../assets/AppIcon.png'

class Video extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
          return(  
            <div style={{width:'100%', position: 'relative', marginTop: '150px',  marginBottom: '150px'}}>
                <img src={AppIcon} alt="Flock" width="200px" style={{borderRadius: 40}}/>
                {/*
                <video id="background-video" autoPlay loop muted playsInline style={{width: '100%', maxHeight: '500px'}}>
                  <source src={oneVideo} type="video/mp4" />
                  <source src={oneVideoMp4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <img src={oneVideoMask} style={{position: 'absolute', pointerEvents:'none', top:'0px', width: '100%',  maxHeight: '500px', display:'block', zIndex:1000}}/>
                */}
            </div>
        )
    }
}

export default Video