import React, {Component, lazy, Suspense} from 'react';
import {Routes ,Route } from 'react-router-dom';

import './App.css';
import './styles/pages/Dashboard.css';

import Home from './pages/Home'

import Privacy from './pages/Privacy'
import TermsAndPrivacy from './pages/TermsAndPrivacy'
import Terms from './pages/Terms';

//import Dashboard from './pages/Dashboard'
//import Redirect from './pages/Redirect'

//Lazy load to speed up landing page time! dont load the rest yet
//Tuto about this lazyload: https://medium.com/javascript-in-plain-english/code-splitting-for-efficient-loading-how-to-use-react-lazy-imports-suspense-and-hoc-fabc8fb6525a
const LazyLoad = ({component: Component, ...rest}) => { //I need to add a proper loading component where Reacr.fragment
  return (<Suspense fallback={<React.Fragment />}> 
            <Component {...rest} />
          </Suspense>)
}

const Dashboard = lazy(() => import('./pages/Dashboard'));
//const Redirect  = lazy(() => import('./pages/Redirect'));

const LazyDashboard = (props) => <LazyLoad component={Dashboard}{...props} />
//const LazyRedirect = (props) => <LazyLoad component={Redirect}{...props} />

// Regular App
class App extends Component {
  render () {
    return (
      <div className="App">
       <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/termsandprivacy' element={<TermsAndPrivacy/>}/>
            <Route path='/privacy' element={<Privacy/>}/>
            <Route path='/terms' element={<Terms/>}/>
            <Route path='/dashboard/:section' element={<div className='Dashboard'><LazyDashboard/></div>}/>
            <Route path='/dashboard' element={<div className='Dashboard'><LazyDashboard/></div>}/>
            <Route element={<Home/>} />
        </Routes>
      </div>
    );
  }
}

export default App;