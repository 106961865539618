import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";

const Terms = () => {

    let navigate = useNavigate()

    /// EFfect
    useEffect(()=>{
        navigate('https://google.es')
    },[])

    return (<React.Fragment/>)
}
export default Terms