import React, {Component} from 'react';

import '../styles/FadeAnimations.css'
import '../styles/Boxes.css'
import { Link } from 'react-router-dom';

import Logo from '../components/home/Video'


class TermsAndPrivacy extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            isMobile: false,
        }
    }

    componentDidMount (){
        //Mobile screen size
        //Add event listener
        this.match = window.matchMedia(`(max-width: 800px)`);
        this.checkWidth = (e) => { 
            if (e.matches) {
                this.setState({isMobile: true})
            }else{
                this.setState({isMobile: false})
            }
        };
        this.checkWidth(this.match);
        //-> fix
        this.methodToSubscribe = () => {this.checkWidth(this.match);} //I use this in between var so I can later unsubscribe
        //-> /fix
        this.match.addEventListener("change", this.methodToSubscribe);

        //Scroll to top
        window.scrollTo(0, 0)
        
    }
    componentWillUnmount() {
        //Unregister media event listener
        this.match.removeEventListener("change", this.methodToSubscribe);
    }

    // Generate policy
    generatePolicy = () => {
        return (<React.Fragment>
            <h1>Privacy Policy</h1>
            <Link to='/privacy' className='menu-link' style={{color: '#aaaaaa'}}>Click Here</Link>
            <h1>EULA</h1>
            <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" className='menu-link' style={{color: '#aaaaaa'}}>Click Here</a>
        </React.Fragment>)
        
    }

    // Contact sales
    contactSales = async() => {
        //Build link here so it's not on the document and bots dont get it
        var m = "m";
        var a = "ailt"
        var x = "o:hola"
        var arr = "@"
        var dom = "nada.studio";
        window.location.href=m+a+x+arr+dom;
    }

    // On Slider change
    onSliderChange = (newValue) => {
        this.setState({
            sliderValue: newValue
        });
    }


    // Close Menu Mobile

    render () {

        //Regular render
        return (
            <React.Fragment>
                <div style={style.whole}>
                    {/* Top container with Wave Background*/}
                    <div style={{ width: '200px', height: '200px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Logo/>
                    </div>
                    <div style={style.firstFrontLeftPart(this.state.isMobile)}>
                        <div className="box_big" style={{fontSize: '14px', width: '500px', padding: '30px'}}>
                        {
                            this.generatePolicy()
                        }
                        </div>
                    </div>

                    <div style={{width: '500px', height: '100px'}}/>
                </div>
                {/* FRONTPAGE FOOTER */}
            </React.Fragment>
        );
    }

}


const style = {
    //The whole div parent
    whole:{	
        width: '100vw',
        //height: '100vh',
        minHeight: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },


    //
    //First front top part
    //

    //Text part
    firstFrontLeftPart: isMobile => {	
        return {
            flex: '1',
            height: '100%',
            fontSize: '10px',

            paddingTop: isMobile ? '10px':'150px',
            boxSizing: 'border-box',

            paddingLeft: '40px',
            paddingRight: '40px',
            
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            color: '#000',
        }
    },
  };

export default TermsAndPrivacy;
