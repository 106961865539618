import React, {Component} from 'react';

import '../styles/FadeAnimations.css'
import '../styles/Boxes.css'

class Privacy extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            isMobile: false,

            policyCorporationName: 'nada studio s.l.',
            policyBusinessName: 'RadarExplorer',
            policyBusinessEmail: 'hola@radarexplorer.app',
            policyBusinessUrl: 'https://radarexplorer.app'
        }
    }

    componentDidMount (){
        //Mobile screen size
        //Add event listener
        this.match = window.matchMedia(`(max-width: 800px)`);
        this.checkWidth = (e) => { 
            if (e.matches) {
                this.setState({isMobile: true})
            }else{
                this.setState({isMobile: false})
            }
        };
        this.checkWidth(this.match);
        //-> fix
        this.methodToSubscribe = () => {this.checkWidth(this.match);} //I use this in between var so I can later unsubscribe
        //-> /fix
        this.match.addEventListener("change", this.methodToSubscribe);

        //Scroll to top
        window.scrollTo(0, 0)
        
    }
    componentWillUnmount() {
        //Unregister media event listener
        this.match.removeEventListener("change", this.methodToSubscribe);
    }

    // Generate policy
    generatePolicy = () => {
        return (<React.Fragment>
            <h1>Privacy Policy</h1>
            <p>We, <span style={{color:'#000', fontFamily: 'NunitoBold'}}>{this.state.policyBusinessName}</span> (owned by {this.state.policyCorporationName}), care about your privacy.<br/>We are based in Europe, and we follow the European Union laws regarding privacy and data protection.</p>
            <h2>Data we collect</h2>
            <p><span style={{color:'#000', fontFamily: 'NunitoBold'}}>From you as a user</span>, we collect your anonymized device token, which is required to grant you access, and nothing else. </p>
            <h2>Location of personal information</h2>
            <p>The data we retain is stored and processed in the European Union. Even though we are based in Spain, some of your data could be stored where our service providers maintain server facilities (Always within the European Union). We will not transfer your data to any other party or region for further processing.</p>
            <p>We are committed to protecting your personal information, by securing our systems, monitoring our servers, and following best practices.</p>
            <h2>Exercise your rights</h2>
            <p>You have the right to know what data of yours we have. You can also request that data to be shared with you, modified, or deleted.<br/>If you are a client of {this.state.policyBusinessName}, note that we might be required by local authorities to keep a copy of some of your personal data for a period of time; in that case, we will let you know at the time you exercise your rights.</p>
            <p>If you wish to exercise any of the rights set out above, please contact us in writing to {this.state.policyBusinessEmail}. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is unreasonable, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
            <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
            <h2>Cookies</h2>
            <p>We do not use cookies.</p>
            <h2>Business transfers</h2>
            <p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy, always respecting your rights. </p>  
        </React.Fragment>)
        
    }

    // Contact sales
    contactSales = async() => {
        //Build link here so it's not on the document and bots dont get it
        var m = "m";
        var a = "ailt"
        var x = "o:hola"
        var arr = "@"
        var dom = "nada.studio";
        window.location.href=m+a+x+arr+dom;
    }

    // On Slider change
    onSliderChange = (newValue) => {
        this.setState({
            sliderValue: newValue
        });
    }


    // Close Menu Mobile

    render () {

        //Regular render
        return (
            <React.Fragment>
                <div style={style.whole}>
                    {/* Top container with Wave Background*/}
                    <div style={style.firstFrontLeftPart(this.state.isMobile)}>
                        <div className="box_big" style={{fontSize: '14px'}}>
                        {
                            this.generatePolicy()
                        }
                        </div>
                    </div>

                    <div style={{width: '300px', height: '100px'}}/>
                </div>
                {/* FRONTPAGE FOOTER */}
            </React.Fragment>
        );
    }

}


const style = {
    //The whole div parent
    whole:{	
        width: '100vw',
        //height: '100vh',
        minHeight: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },


    //
    //First front top part
    //

    //Text part
    firstFrontLeftPart: isMobile => {	
        return {
            flex: '1',
            height: '100%',
            fontSize: '10px',

            paddingTop: isMobile ? '10px':'150px',
            boxSizing: 'border-box',

            paddingLeft: '40px',
            paddingRight: '40px',
            
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            color: '#000',
        }
    },
  };

export default Privacy;
